import React from "react";

import './NotFound.css'

const NotFound = () =>{

    return(
        <div className="NotFound">
            <img src="notfound.avif" alt="notfound" />
        </div>
    )
}

export default NotFound;